import { Jobs } from "./types";
import { ListItemType } from "components/atoms/ListItem";

export const jobs: Jobs = [
  {
    type: ListItemType.Maintenance,
    content: "Drain up",
    dueDate: 1740830431865,
  },
  {
    type: ListItemType.Maintenance,
    content: "Boiler service",
    dueDate: 1746097231865,
  },
];
