import InfoDialog from "components/atoms/InfoDialog";
import { useJobs } from "modules/Jobs";
import JobsList from "components/molecules/JobsList";
import "./InfoPage.scss";

function InfoPage() {
  const { jobs } = useJobs();

  return (
    <div className="info-page">
      <div className="dialog">
        <InfoDialog>
          <div className="title">Bertie is hibernating</div>
          <div className="text">He had a lovely 2024 season and is excited to see everyone again in Spring 2025</div>
        </InfoDialog>
      </div>
      <div className="jobs">
        <JobsList jobs={jobs} />
      </div>
    </div>
  );
}

export default InfoPage;
